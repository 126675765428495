<template>
  <footer
    class="bg-pharm-dark px-3 sm:px-6 md:px-8 lg:px-10 pt-10 text-white bg-50 bg-no-repeat bg-right flex justify-center pb-20 lg:pb-10"
  >
    <div class="max-w-8xl w-full">
      <img src="../assets/images/logo-text-white_256.png" class="h-12 w-auto" />
      <div
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 mt-10 mb-10 sm:mt-32 sm:mb-28 text-lg font-medium"
      >
        <div>
          <ul>
            <li>
              <a class="underline-animated" href="https://cognipharm.com" target="_blank"
                >CogniPharm Home</a
              >
            </li>
            <li>
              <a class="underline-animated" href="https://cognipharm.com/about" target="_blank"
                >About</a
              >
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <a
                class="underline-animated"
                href="https://cognipharm.com/submit-project"
                target="_blank"
                >Submit project for funding</a
              >
            </li>
            <li>
              <a
                class="underline-animated"
                href="https://discord.com/invite/cognipharm"
                target="_blank"
                >Join the community</a
              >
            </li>
          </ul>
        </div>
      </div>
      <p class="text-sm text-gray-400">
        © {{ dayjs().format('YYYY') }} CogniPharm DAO. All rights reserved.
        <a href="https://cognipharm.com/privacy-policy" target="_blank" class="underline">Privacy Policy</a>
      </p>
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  setup() {
    return {
      dayjs,
    }
  },
})
</script>
