<template>
  <li
    class="bg-opacity-10 col-span-1 flex flex-col border border-gray-300 text-center space-y-4"
    :class="{'bg-success': hasPassed, 'bg-danger' : hasFailed || quorumFailedAndEnded}"
  >

    <div v-if="category == 'Funding'"
      class="bg-pharm-funding py-2">
      <span
        class="uppercase inline font-medium text-white"
        >{{ category }}</span
      >
    </div>
    <div v-else-if="category == 'IP'"
      class="bg-pharm-ip py-2">
      <span
        class="uppercase inline font-medium text-white"
        >{{ category }}</span
      >
    </div>
    <div v-else
      class="bg-pharm-governance py-2">
      <span
        class="uppercase inline font-medium text-white"
        >{{ category }}</span
      >
    </div>

    <div class="py-2 px-10 space-y-4">
    <div
      class="flex flex-shrink-0 items-center w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 justify-center mx-auto"
    >
      <svg
        v-if="category == 'Funding'"
        width="71"
        height="71"
        viewBox="0 0 299 299"
        fill="#0B6623"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M153.718,102.736v-5.255c0-4.143-3.357-7.5-7.5-7.5c-4.143,0-7.5,3.357-7.5,7.5v5.434
            c-13.347,3.502-23.231,15.789-23.231,30.368c0,14.575,9.885,26.858,23.231,30.358v31.528c-6.504-3.294-12.674-9.404-14.898-12.031
            c-2.666-3.17-7.396-3.58-10.567-0.913c-3.17,2.665-3.579,7.396-0.913,10.567c1.425,1.694,12.566,14.521,26.379,18.439v5.566
            c0,4.143,3.357,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5v-5.296c13.639-3.298,23.812-15.717,23.812-30.499
            c0-14.78-10.173-27.197-23.812-30.495v-32.136c6.776,2.537,12.915,7.732,15.226,10.12c2.864,2.988,7.605,3.089,10.596,0.23
            c2.993-2.863,3.1-7.61,0.237-10.605C178.418,116.696,167.644,105.824,153.718,102.736z M130.486,133.283
            c0-6.139,3.327-11.492,8.231-14.294v28.579C133.813,144.768,130.486,139.417,130.486,133.283z M162.529,181.003
            c0,6.371-3.593,11.89-8.812,14.592v-29.18C158.937,169.115,162.529,174.632,162.529,181.003z"/>
          <path d="M266.619,206.31c-8.108,0-15.524,3.003-21.212,7.945l-21.418-14.278c7.028-12.71,11.036-27.313,11.036-42.837
            c0-18.604-5.754-35.886-15.569-50.171l14.45-11.562c5.055,3.265,11.067,5.169,17.52,5.169c17.857,0,32.385-14.528,32.385-32.386
            c0-17.858-14.527-32.387-32.385-32.387c-17.858,0-32.387,14.528-32.387,32.387c0,5.959,1.625,11.544,4.444,16.346l-13.53,10.826
            c-16.151-16.661-38.753-27.034-63.735-27.034c-21.802,0-41.789,7.901-57.262,20.985L60.213,60.832
            c2.891-4.845,4.558-10.501,4.558-16.541c0-17.858-14.527-32.386-32.384-32.386C14.528,11.905,0,26.433,0,44.291
            c0,17.857,14.528,32.386,32.387,32.386c6.392,0,12.354-1.87,17.379-5.08l28.588,28.328c-13.06,15.467-20.944,35.437-20.944,57.216
            c0,18.907,5.942,36.449,16.053,50.867l-27.127,22.606c-3.25-1.671-6.929-2.621-10.827-2.621c-13.104,0-23.764,10.66-23.764,23.763
            s10.66,23.763,23.764,23.763c13.102,0,23.761-10.66,23.761-23.763c0-3.726-0.864-7.253-2.398-10.395l26.203-21.837
            c16.11,16.306,38.467,26.427,63.144,26.427c11.605,0,22.694-2.24,32.865-6.307l6.684,13.366c-3.642,3.615-5.902,8.62-5.902,14.144
            c0,10.997,8.946,19.944,19.942,19.944c10.997,0,19.943-8.947,19.943-19.944c0-10.996-8.946-19.942-19.943-19.942
            c-0.056,0-0.109,0.008-0.164,0.008l-7.153-14.305c8.863-5.433,16.702-12.377,23.161-20.471l20.998,13.998
            c-1.552,3.783-2.414,7.919-2.414,12.254c0,17.857,14.527,32.385,32.385,32.385s32.385-14.527,32.385-32.385
            C299.004,220.838,284.477,206.31,266.619,206.31z M251.426,50.804c9.586,0,17.385,7.8,17.385,17.387
            c0,9.587-7.799,17.386-17.385,17.386c-9.587,0-17.387-7.799-17.387-17.386C234.039,58.604,241.839,50.804,251.426,50.804z
            M35.508,260.518c-4.832,0-8.764-3.931-8.764-8.763s3.932-8.763,8.764-8.763c4.831,0,8.761,3.931,8.761,8.763
            S40.339,260.518,35.508,260.518z M204.75,267.155c0,2.727-2.218,4.944-4.943,4.944s-4.942-2.218-4.942-4.944
            c0-2.726,2.217-4.942,4.942-4.942S204.75,264.429,204.75,267.155z M32.387,61.676C22.8,61.676,15,53.877,15,44.291
            c0-9.587,7.8-17.386,17.387-17.386c9.585,0,17.384,7.799,17.384,17.386C49.771,53.877,41.972,61.676,32.387,61.676z
            M146.218,230.951c-40.698,0-73.809-33.111-73.809-73.811c0-40.7,33.11-73.812,73.809-73.812c40.697,0,73.808,33.111,73.808,73.812
            C220.025,197.839,186.915,230.951,146.218,230.951z M266.619,256.082c-9.586,0-17.385-7.799-17.385-17.385
            c0-9.587,7.799-17.387,17.385-17.387s17.385,7.8,17.385,17.387C284.004,248.283,276.205,256.082,266.619,256.082z"/>
        </g>
      </svg>

      <svg
        v-else-if="category == 'IP'"
        width="71"
        height="71"
        viewBox="0 0 256 256"
        fill="#C49102"
        stroke="#C49102"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M128,28A100,100,0,1,0,228,128,100.113,100.113,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.10447,92.10447,0,0,1,128,220ZM92.00293,127.99512a36.00162,36.00162,0,0,0,64.7998,21.60351,4.0003,4.0003,0,0,1,6.39649,4.80567,43.99976,43.99976,0,1,1,0-52.81885,4.00044,4.00044,0,0,1-6.39649,4.80615,36.00163,36.00163,0,0,0-64.7998,21.60352Z"/>
      </svg>

      <svg
        v-else-if="category == 'Project'"
        width="84"
        height="84"
        viewBox="0 0 84 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M56.8 33.1C59.1 41.2 60.1 48.9 54.5 54.5"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M56.0999 1.5C50.5999 7 51.4999 14.5 53.5999 22.4"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M28.8 28.8C42.5 15.1 68.2001 40.8 81.8001 27.2"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M30 62C31.9 69.5 32.5 76.5 27.2 81.8"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M28.8 28.8C23.4 34.2 24.1 41.4 26.2 49"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M1.5 56.1C15.2 42.4 40.9 68.1 54.5 54.5"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M38.3 43.6L47 52.4"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M30.8999 36.3L34.2999 39.6"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M48.7 43.3L52.4 47"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M36.3 30.9L44.6 39.3"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M46.3 30.3L53 37"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.8999 70.2L25.0999 74.3"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M9 58.2L15.8 65"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M19.7 58.3L24.9 63.5"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M31.8999 47.9L36.9999 53"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M67.8999 18.6L74.2999 25.1"
          stroke="black"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M58.2 9L63.7999 14.5"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M57.6001 19L64.3001 25.7"
          stroke="#6256EC"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>

      <!-- show governance icon as fallback -->
      <svg
        v-else
        width="71"
        height="71"
        viewBox="0 0 32 32"
        fill="#6A0DAD"
        stroke="#6A0DAD"
        xmlns="http://www.w3.org/2000/svg"
      >
      <path id="blockchain_1_" d="M30,30.36h-6c-0.199,0-0.36-0.161-0.36-0.36v-2.64h-4.28V30c0,0.199-0.161,0.36-0.36,0.36h-6
	c-0.199,0-0.36-0.161-0.36-0.36v-2.64H8.36V30c0,0.199-0.161,0.36-0.36,0.36H2c-0.199,0-0.36-0.161-0.36-0.36v-6
	c0-0.199,0.161-0.36,0.36-0.36h2.64v-4.28H2c-0.199,0-0.36-0.161-0.36-0.36v-6c0-0.199,0.161-0.36,0.36-0.36h2.64V8.36H2
	C1.801,8.36,1.64,8.199,1.64,8V2c0-0.199,0.161-0.36,0.36-0.36h6c0.199,0,0.36,0.161,0.36,0.36v2.64h4.28V2
	c0-0.199,0.161-0.36,0.36-0.36h6c0.199,0,0.36,0.161,0.36,0.36v2.64h4.279V2c0-0.199,0.161-0.36,0.36-0.36h6
	c0.199,0,0.36,0.161,0.36,0.36v6c0,0.199-0.161,0.36-0.36,0.36h-2.64v4.28H30c0.199,0,0.36,0.161,0.36,0.36v6
	c0,0.199-0.161,0.36-0.36,0.36h-2.64v4.279H30c0.199,0,0.36,0.161,0.36,0.36v6C30.36,30.199,30.199,30.36,30,30.36z M24.36,29.64
	h5.279v-5.28H24.36V29.64z M13.36,29.64h5.28v-5.28h-5.28C13.36,24.36,13.36,29.64,13.36,29.64z M2.36,29.64h5.28v-5.28H2.36V29.64z
	 M19.36,26.64h4.279V24c0-0.199,0.161-0.36,0.36-0.36h2.64v-4.28H24c-0.199,0-0.36-0.161-0.36-0.36v-2.64h-4.28V19
	c0,0.199-0.161,0.36-0.36,0.36h-2.64v4.279H19c0.199,0,0.36,0.161,0.36,0.36V26.64z M8.36,26.64h4.28V24
	c0-0.199,0.161-0.36,0.36-0.36h2.64v-4.28H13c-0.199,0-0.36-0.161-0.36-0.36v-2.64H8.36V19c0,0.199-0.161,0.36-0.36,0.36H5.36v4.279
	H8c0.199,0,0.36,0.161,0.36,0.36V26.64z M27,18.64h2.64v-5.28h-5.28v5.28H27z M16,18.64h2.64v-5.28h-5.28v5.28H16z M5,18.64h2.64
	v-5.28H2.36v5.28H5z M19.36,15.64h4.279V13c0-0.199,0.161-0.36,0.36-0.36h2.64V8.36H24c-0.199,0-0.36-0.161-0.36-0.36V5.36h-4.28V8
	c0,0.199-0.161,0.36-0.36,0.36h-2.64v4.28H19c0.199,0,0.36,0.161,0.36,0.36V15.64z M8.36,15.64h4.28V13
	c0-0.199,0.161-0.36,0.36-0.36h2.64V8.36H13c-0.199,0-0.36-0.161-0.36-0.36V5.36H8.36V8c0,0.199-0.161,0.36-0.36,0.36H5.36v4.28H8
	c0.199,0,0.36,0.161,0.36,0.36V15.64z M27,7.64h2.64V2.36h-5.28v5.28C24.36,7.64,27,7.64,27,7.64z M16,7.64h2.64V2.36h-5.28v5.28
	C13.36,7.64,16,7.64,16,7.64z M5,7.64h2.64V2.36H2.36v5.28C2.36,7.64,5,7.64,5,7.64z"/>
      </svg>
    </div>

    <h2
      class="text-2xl font-semibold leading-tight hover:text-vita-purple transition-colors duration-150"
    >
      <a :href="proposal.link" target="_blank">{{ cleanTitle }}</a>
    </h2>

    <span
      v-if="proposal.state == 'pending' && proposal.start > dayjs().unix()"
      class="font-medium text-gray-600"
    >
      <fa icon="clock" class="mr-0.5 text-gray-400" />
      Vote starting in {{ dayjs.unix(proposal.start).fromNow(true) }}
    </span>
    <span v-else-if="proposal.state == 'active'" class="font-medium text-orange-400">
      <fa icon="vote-yea" class="mr-0.5 text-orange-300" />
      Voting (ends in
      {{ dayjs.unix(proposal.end).fromNow(true) }})
    </span>
    <span v-else-if="hasPassed" class="font-medium text-success">
      <fa icon="thumbs-up" class="mr-0.5 text-success" />
      Proposal Passed
    </span>
    <span v-else-if="hasFailed" class="font-medium text-danger">
      <fa icon="thumbs-down" class="mr-0.5 text-danger" />
      Proposal Failed
    </span>
    <span v-else-if="quorumFailedAndEnded && !wasOnChainProposal" class="font-medium text-danger">
      <fa icon="thumbs-down" class="mr-0.5 text-danger" />
      Quorum not reached
    </span>
    <!-- TO REMOVE -->
    <span v-else-if="quorumFailedAndEnded && wasOnChainProposal" class="font-medium text-success">
      <fa icon="thumbs-up" class="mr-0.5 text-success" />
      Proposal Passed
    </span>
    <span v-else class="font-medium text-gray-600">Pending</span>

    <div v-if="proposal.state != 'active'">
      <transition name="fade" mode="out-in">
        <div v-if="loadingScores" class="text-sm text-gray-600" key="loading">
          <fa icon="spinner" spin class="mr-0.5" />
          Loading results…
        </div>
        <div v-else class="flex flex-col items-center" key="results">
          <div class="bg-gray-400 flex h-1.5 overflow-hidden w-full">
            <div
              class="bg-success duration-1000 ease-in-out h-full transition-all"
              :style="{
                width: yesPercentage - 0.5 + '%',
              }"
            />
            <div class="bg-white" style="width: 1%" />
            <div
              class="bg-danger duration-1000 ease-in-out h-full transition-all"
              :style="{
                width: noPercentage - 0.5 + '%',
              }"
            />
          </div>
          <div class="mt-3 font-medium text-gray-300">
            <span class="text-success">{{ yesPercentage.toFixed(2) }}% Yes</span> •
            <span class="text-danger">{{ noPercentage.toFixed(2) }}% No</span>
          </div>
          <span class="text-gray-600" v-if="!wasOnChainProposal">
            {{ new Intl.NumberFormat('en', { maximumFractionDigits: 0 }).format(numTotalVotes) }}
            Total Votes
            <span v-if="quorumReached || !proposal.state == 'active'"
              >({{ Math.round(quorumPercentage) }}% of quorum)</span
            >
          </span>
          <!-- the replayed proposals don't reach quorum numbers because of the replay,
             this is a workaround to show the correct outcome -->
          <div
            v-if="
              (proposal.state == 'active' || proposal.state == 'closed') &&
              !quorumReached &&
              !wasOnChainProposal
            "
            class="mt-2 bg-orange-50 font-semibold text-sm px-4 py-1.5 rounded-full text-orange-400"
          >
            <fa icon="exclamation-triangle" class="mr-0.5 text-orange-300" />
            Quorum not met ({{ quorumPercentage.toFixed(2) }}%)
          </div>
          <!--TO REMOVE-->
          <div
            v-if="wasOnChainProposal"
            class="mt-2 bg-gray-50 text-xs px-4 py-1.5 rounded-xl text-gray-500"
          >
            <fa icon="exclamation-triangle" class="mr-0.5 text-gray-400" />
            This was originally an on-chain proposal and has been mirrored to Snapshot for
            completeness.
          </div>
        </div>
      </transition>
    </div>

    <div class="flex-grow" />

    <div class="pt-1">
      <a :href="proposal.link" target="_blank">
        <base-button v-if="proposal.state == 'active'" type="secondary">Vote</base-button>
        <base-button v-else type="outline">View</base-button>
      </a>
    </div>
  </div>
  </li>
</template>

<script>
import { defineComponent, computed, ref, onBeforeMount } from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import BaseButton from '@/components/BaseButton.vue'

export default defineComponent({
  components: { BaseButton },
  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    dayjs.extend(relativeTime)

    const loadingScores = ref(true)
    const numTotalVotes = ref(0)
    const numYesVotes = ref(0)
    const numNoVotes = ref(0)
    const numAbstainVotes = ref(0)

    onBeforeMount(() => {
      if (props.proposal.state == 'closed') {
        numTotalVotes.value = props.proposal.scores_total
        numYesVotes.value = props.proposal.scores[0]
        numNoVotes.value = props.proposal.scores[1]
        numAbstainVotes.value = props.proposal.scores[2]
        loadingScores.value = false
      }

      if (votesQueryResult.value) {
        // Not needed with shielded voting enabled
        //calculateScores(votesQueryResult.value.votes)
      }
    })

    const { result: votesQueryResult, onResult } = useQuery(
      gql`
        query Votes($proposalId: String!) {
          votes(first: 20000, where: { proposal: $proposalId }) {
            id
            voter
            choice
          }
        }
      `,
      () => ({
        proposalId: props.proposal.id,
      }),
      () => ({
        enabled: props.proposal.state != 'closed',
        clientId: 'snapshot',
      }),
    )

    onResult((queryResult) => {
      // Not needed with shielded voting enabled
      //calculateScores(queryResult.data.votes)
    })

    async function calculateScores(votes) {
      const voters = votes.map((v) => v.voter)
      // let votesWithBalance = []

      if (voters.length) {
        try {
          // Don't use the more complex calculation that was necessary for the old snapshot library

          // const scores = await snapshot.utils.getScores(
          //   process.env.VUE_APP_SNAPSHOT_SPACE,
          //   [props.proposal.strategies[0]],
          //   props.proposal.network,
          //   voters,
          //   props.proposal.snapshot,
          // )

          // const scoreLowercase = {}
          // for (const [key, value] of Object.entries(scores[0])) {
          //   scoreLowercase[key.toLowerCase()] = value
          // }

          // votes.forEach(function (v) {
          //   let vWithBalance = {
          //     id: v.id,
          //     voter: v.voter,
          //     choice: v.choice,
          //     balance: scoreLowercase[v.voter.toLowerCase()],
          //   }
          //   votesWithBalance.push(vWithBalance)
          //   if (scoreLowercase[v.voter.toLowerCase()] === undefined) {
          //     console.log(
          //       'Warning! Snapshot data seems incorrect. No balance found for voter: ' + v.voter,
          //     )
          //   }
          // })

          // let resultsByVoteBalance = props.proposal.choices.map((choice, i) =>
          //   votesWithBalance
          //     .filter((vote) => vote.choice === i + 1)
          //     .reduce((sum, item) => (item.balance != undefined ? sum + item.balance : sum), 0),
          // )

          numTotalVotes.value = props.proposal.scores_total
          numYesVotes.value = props.proposal.scores[0]
          numNoVotes.value = props.proposal.scores[1]
          numAbstainVotes.value = props.proposal.scores[2]
          loadingScores.value = false
        } catch (error) {
          console.log(error)
        }
      } else {
        loadingScores.value = false
      }
    }

    const cleanTitle = computed(function () {
      return props.proposal.title.replace(/ *\[[^\]]*]/g, '')
    })

    const category = computed(function () {
      if (props.proposal.title.includes('[Project]')) {
        return 'Project'
      } else if (props.proposal.title.includes('[IP]')) {
        return 'IP'
      } else if (props.proposal.title.includes('[Funding]')) {
        return 'Funding'
      } else {
        return 'Governance'
      }
    })

    const yesPercentage = computed(function () {
      if (numYesVotes.value > 0) {
        // Subtract Abstain votes because we're not displaying them visually
        return (numYesVotes.value / (numTotalVotes.value - numAbstainVotes.value)) * 100.0
      } else {
        return 0
      }
    })

    const noPercentage = computed(function () {
      if (numNoVotes.value > 0) {
        // Subtract Abstain votes because we're not displaying them visually
        return (numNoVotes.value / (numTotalVotes.value - numAbstainVotes.value)) * 100.0
      } else {
        return 0
      }
    })

    const quorumPercentage = computed(function () {
      return (numTotalVotes.value / parseFloat(props.proposal.space.voting.quorum)) * 100.0
    })

    const quorumReached = computed(function () {
      return numTotalVotes.value >= parseFloat(props.proposal.space.voting.quorum)
    })

    const quorumFailedAndEnded = computed(function () {
      return (
        props.proposal.state != 'active' &&
        numTotalVotes.value < parseFloat(props.proposal.space.voting.quorum)
      )
    })

    const hasPassed = computed(function () {
      return (
        props.proposal.state != 'active' &&
        quorumReached.value == true &&
        numYesVotes.value > numNoVotes.value
      )
    })

    const hasFailed = computed(function () {
      return (
        props.proposal.state != 'active' &&
        quorumReached.value == true &&
        numYesVotes.value < numNoVotes.value
      )
    })

    const wasOnChainProposal = computed(function () {
      return props.proposal.start < 1642201905
    })

    const propBackground = computed(function () {
      return hasPassed.value ? 'bg-success' : hasFailed.value ? 'bg-danger' : 'bg-white'
    })

    return {
      dayjs,
      relativeTime,
      cleanTitle,
      category,
      loadingScores,
      numTotalVotes,
      yesPercentage,
      noPercentage,
      quorumPercentage,
      quorumFailedAndEnded,
      hasPassed,
      hasFailed,
      quorumReached,
      wasOnChainProposal,
    }
  },
})
</script>

<style scoped>
.proposal-type-label {
  background: color-mod(theme('colors.vita-purple') lightness(+33%));
}
</style>
